<template>
    <div style="display: flex;justify-content: center;">
        <div style="width:90%;">
            <div style="display: flex;align-items: center;margin-top: 30px;margin-left: 10px;cursor: pointer;"
                @click="goBack">
                <i style="font-weight: bold;" class="el-icon-arrow-left" />
                <p style="color: var(--c-primary);margin-left: 4px;font-weight: bold;">{{
                    applicationInfo.project_info.project_name }}
                    Payment</p>
            </div>
            <div class="pay-info"
                style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;font-family: GravityBold;">
                <div style="display: flex;justify-content: space-between;">
                    <p>Program</p>
                    <p style=" color: var(--c-primary);">{{  applicationInfo.project_info.project_name }}</p>
                </div>

                <!-- <div style="display: flex;justify-content: space-between;margin:15px 0px">
                    <p>Entry Title</p>
                    <p style=" color: var(--c-primary);">{{ applicationInfo.entry.title }}</p>
                </div> -->

                <div style="display: flex;justify-content: space-between;margin:15px 0px">
                    <p>Entry ID</p>
                    <p style="font-weight: bold;color: var(--c-primary);">{{ applicationInfo.entry_id
                        }}</p>
                </div>

                <div style="display: flex;justify-content: space-between;margin:15px 0px">
                    <p>Standard Registration Fee</p>
                    <p style="font-weight: bold;color: var(--c-primary);">USD {{ applicationInfo.original_price
                        }}</p>
                </div>
                <div style="display: flex;justify-content: space-between;margin:15px 0px">
                    <p>Reduction</p>
                    <p style="color: var(--c-primary);">USD {{ applicationInfo.original_price - applicationInfo.total_price }}</p>
                </div>
                <div style="display: flex;justify-content: space-between;margin:15px 0px">
                    <p>Fee after Reduction*</p>
                    <p style="color: var(--c-primary)">USD {{ applicationInfo.total_price }}</p>
                </div>

                <div style="display: flex;justify-content: space-between;margin:15px 0px">
                    <p>Payment Service Fee**</p>
                    <p style="color: var(--c-primary)">USD {{ applicationInfo.service_price }}</p>
                </div>
               

                <div style="width: 100%;height: 1px;background-color: var(--c-primary);"> </div>

                <div style="display: flex;justify-content: space-between;margin:15px 0px">
                    <p>Total Order Amount</p>
                    <p style="color: var(--c-primary)">USD {{ applicationInfo.amount
                        }}</p>
                </div>
                <p style="font-style: italic;font-family: GravityNormal;">*The service fee is charged by the payment
                    platform. It is not
                    included in the registration fee.</p>
            </div>


            <div style="margin-top: 20px;color: red;word-wrap: normal;">
                *WSPR provides tiered fee reductions based on regional economy, income levels, tuition costs, etc.<span style="color: #5A9CF8;margin-left: 10px;text-decoration: underline" @click="showLearnMore()">Learn more.</span><br>
                **The payment service fee is charged by the payment platform at a rate of 3.9% plus 0.43 USD per transaction.
            </div>
     
            <div style="margin-top: 20px;">
                <p style="font-family: GravityBold;;">Terms and Conditions</p>
                <p style="margin-top: 10px;line-height: 24px;font-size: 16px;background-color:#fff5f4;width: 100%;padding: 15px 20px;box-sizing: border-box;"
                    v-html="project_refund_policy">
                </p>
            </div>
            <div style="margin-top: 20px;display: flex;align-items: center;justify-content: center;">
                <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                        :src="require('../../assets/image/unchecked.png')" />
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                        :src="require('../../assets/image/checked.png')" />
                </div>
                <p> I have read and agree on the terms and conditions.</p>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;margin: 30px 0px;">
                <el-button class="btn" @click="goBack">Cancel</el-button>
                <el-button class="btn" type="primary" @click="goStripe">Pay</el-button>
            </div>
        </div>


        <el-dialog class="dialog" title="Fee Reduction Policy" :visible.sync="dialogVisible" :close-on-click-modal="false" width="80%">



            <el-row>
                <el-col :span="16">

                    <div style="width: 100%;height: 500px;overflow-y: auto;">
                    <pdf :src="pdfSrc" width="100%"  
                        v-for="i in 4"
                        :key="i"
                        :page="i"
                        ref="pdf"
                        style="width: 100%;"
                    />

                    </div>

                </el-col>
                <el-col :span="8">

                
                    <div class="levelTitle">Your Reduction Level</div>
                    <el-table :row-class-name="tableRowClassName" :data="tierData1" header-cell-class-name="adorn" size="medium" border ref="leftTable" class="left-table hide-table-header" >
                        <el-table-column label="" align="center" prop="key" ></el-table-column>
                        <el-table-column label="" align="center" prop="value" width="50px"></el-table-column>
                    </el-table>

        
                    <div class="tierTip">
                        If you've received financial aid, scholarships, or subsidies that have cut your tuition fees below the standard amount, you have the opportunity to apply for reclassification into a lower Tuition Tier for greater fee reduction.
                    </div>

                    <div class="applyClass" style="position: relative;">

                        <input class="myFileUpload"  name="file" type="file"  accept=".pdf" @change="getFile" />

                        <span class="addInput2">
                            Apply for lower tier
                        </span>

                    
                    </div>

                    <div style="margin-top: 20px;margin-left: 20px;" v-if="file"> {{ file.name }}</div>

                    <div class="tierTip1">Compile documentation of your actual tuition fee
                        payment into one PDF titled with your full name.</div>



                    <div class="dialog-footer">
                        <el-button type="primary" @click="dialogVisible=false">Cancel</el-button>
                        <el-button
                            :loading="loadDialog"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(255, 255, 255, 0.2)"
                            type="primary"
                            @click="submitTier()"
                            >Submit</el-button
                        >
                    </div>

                


                </el-col>
            </el-row>



            </el-dialog>

    </div>
</template>

<script>
import { getApplicationInfo, getStripeUrl,getProjectDetail ,getQNToken,uploadStudentTuitionTier,
    freePayApplication
} from "../../api/index";
import VueQr from "vue-qr";
import * as qiniu from 'qiniu-js'

import pdf from 'vue-pdf'

import { showLoading, hideLoading } from '../../utils/loadingutil'

import {
    getUserId
} from '../../utils/store'

export default {
    
    data() {
        return {
            application_id: this.$route.query.id,
            applicationInfo: {},
            project_refund_policy: '',
            start_date: '',
            end_date: '',
            isAgree: false,
            dialogVisible:false,
            loadDialog: false,
            tierData:[ {'level':'1','price':'>15000 USD'},
                 {'level':'2','price':'>8000 USD'},
                 {'level':'3','price':'>4000 USD'},
                 {'level':'4','price':'>2000 USD'},
                 {'level':'5','price':'>1500 USD'},
                 {'level':'6','price':'>1000 USD'},
                 {'level':'7','price':'>500 USD'},
                 {'level':'8','price':'<500 USD and >0'},
                 {'level':'9','price':'FREE'},

                ],
            tierData1:[],
            file:null,
            pdfSrc: 'https://ap.learningfirst.tech/WSPR%20Fee%20Reduction%20Policy%200624.pdf',
            numPages:0,
            price_level:'1'
        };
    },
    components: {
        VueQr,
        pdf
    },
    mounted() {
        let date = new Date()
        this.start_date = this.convertTimeToEng(date.getTime())
        date.setFullYear(date.getFullYear() + 1)
        var nextYearTimestamp = date.getTime() - 24 * 60 * 60 * 1000 //前一天
        this.end_date = this.convertTimeToEng(nextYearTimestamp)
        this.fetchData();
        this.getProjectDetail()
    },
    methods: {

        calcReductionFee() {
            let fee = 195 - (this.applicationInfo.total_price)
            return '(' + fee + ')'
        },

        async fetchData() {
            const result = await getApplicationInfo(this.application_id);
            // if (result.data.code != 0) {
            //   this.$message.error(result.data.msg)
            //   return
            // }
            this.applicationInfo = result.data.data
            if (this.applicationInfo.application_status == '已缴费' || this.applicationInfo.application_status == '无需缴费') {
                this.goBack()
                return
            }
            this.project_refund_policy = this.applicationInfo.project_info.project_refund_policy
            this.project_refund_policy = this.project_refund_policy.replaceAll('\r\n\r\n', '<br><br>')
        },
        goBack() {
            this.$router.go(-1)
        },

        goStripe() {
            if (!this.isAgree) {
                this.$message.warning("Please read terms and agree it");
                return;
            }
            // getStripeUrl(this.applicationInfo._id.$id).then(res => {
            //     // if (res.data.code === 1) {
            //     //     this.$message.success('Pay success~')
            //     //     this.goBack()
            //     //     return
            //     // }
            //     // if (res.data.code === 0) {
            //     //     window.open(res.data.data, '_blank')
            //     // } else {
            //     //     this.$message.error(res.data.msg)
            //     // }
            // })

            if (this.applicationInfo.amount == 0){
                

                freePayApplication(this.applicationInfo._id.$id).then(res => {
                    if (res.data.code === 1) {
                        this.$message.success('Pay success~')
                        this.goBack()
                        return
                    }else {
                        this.$message.error(res.data.msg)
                    }
                })

                return
            }


            if (this.applicationInfo.amount == null || this.applicationInfo.amount < 0){
                this.$message.warning("Amount Error");
                return;
            }

            const href = this.$router.resolve({
                path: `/pay`,
                query: {
                    id: this.applicationInfo._id.$id,
                    amount:this.applicationInfo.amount
                }
            }).href;
            //打开新的页面 到 href 这个页面
            window.open(href, "_blank");

            // this.$router.push({
            //     path: '/pay',
            //     query: {
            //         id: this.applicationInfo._id.$id,
            //         amount:this.applicationInfo.amount
            //     }
            // })
          
        },
        convertTimeToEng(time) {
            var date = new Date(time);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + " " + day + ", " + year;
        },

        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },

        getOrderPay(){
            getOrder({
                id: this.applicationInfo._id.$id,
            }).then((res) => {
                if (res.data.code === 0) {
                    
                }
            })
        },

        getProjectDetail(){

            getProjectDetail( '2024WSPR' ,getUserId() ).then((res) => {

                this.price_level = res.data.data.price_level
                console.log(1231,res)

                this.tierData1 = res.data.data.level_arr

            });


        },

        tableRowClassName({row, rowIndex}) {
            if (rowIndex === 1) {
            return 'success-row';
            } else {
            return 'warning-row';
            }
        },
        showLearnMore(){
            this.dialogVisible = true
        },

        getFile (e) {
            const file = e.target.files[0]

            console.log(file)
            if (file.size / 1024 / 1024 > 20) {
                this.$message({
                message: 'The file size cannot exceed 20 MB. Please upload again.',
                type: 'error'
                })
                this.file = null
                return false
            } else {
                this.file = file
            }

            console.log(123,this.file)

            // this.videoName = this.file.name
            // this.uploadFile()
        },
        async getQiniuToken () {
            return new Promise((resolve) => {
                getQNToken().then((res) => {
                resolve(res)
                })
            })
        },
        submitTier(){


            showLoading()

            this.uploadVideoFile()


        },
        async uploadVideoFile () {
            console.log(1232131213)

            const result = await this.getQiniuToken()

            console.log(8888, result)

            if (result.data.code === 0) {
                console.log(1222)

                this.proVisible = true
                this.process = 1

                const token = result.data.data
                this.observable = qiniu.upload(
                this.file,
                new Date().getTime() + this.file.name,
                token
                )
                const observer = {
                complete: (res) => {
                    var url = 'https://ap.learningfirst.tech/' + res.key

                    
                    // console.log(111111222,url)

                    // resolve(url)
                    this.submitTierInfo(url)

                },
                next: (res) => {
                    // this.process = parseFloat(res.total.percent.toFixed(1))
                }
                }
                this.observable.subscribe(observer)
            } else {
            }
        },
            
        submitTierInfo(url){
            
            uploadStudentTuitionTier( getUserId(),'2024WSPR' ,url ).then((res) => {

                hideLoading()

                if (res.data.code == 0) {
                    this.$message.success('Submit Successfully')
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    
    },
};
</script>

<style lang="scss" scoped>
.pay-info {
    background: #fff5f4;
    color: #7F7F7F
}

.updateBtn {
  width: 150px;
  margin-top: 20px;
  // margin-left: 100px;
}
.partP {
  margin: 0;
  padding: 0;
  height: 40px;
  line-height: 40px;
  left: 21px;
  font-size: 16px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 20px;
}
.left-table{
  margin-top: 2px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
  // width: 50%;
  word-break: normal;

}
.dialog-footer{
  // display: flex;
  margin-top: 10px;
  margin-left: 20px;
  width: 100%;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需要 */
  height: 80px; /* 容器高度 */
  // background-color: red;
}
.tierTip{
  word-break: normal;
  margin-left: 20px;
  margin-top: 20px;
  line-height: 20px;
  // width: 40%;
}
.levelClass{
  float: left;
  width: 160px;
  background-color: gray;
  color: white;
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin-left: 20px;
  margin-top: 30px;

}
.applyClass{
  // float: left;
  margin-left: 20px;
  margin-top: 30px;
  width: 160px;
  height: 36px;
  // line-height: 36px;
  font-size: 13px;
  text-align: center;
  color: white;
  cursor: pointer;
  // background-color: red;
}
.tierTip1{
  color: red;
  word-break: normal;
  margin-left: 20px;
  margin-top: 20px;
  line-height: 20px;
}
.addInput2{
  font-family: "DDINAlternateRegular";
  font-weight: 600;
  color: white;
  background-color: #D64643;
  // border: 1.5px solid #0E4890;
  height: 36px;
  width: 160px;
  line-height: 36px;
  text-align: center;
  // background: white;
  // border-radius: 5px;
  // padding: 4px 12px;
  cursor: pointer;
  margin-top: 0px;
  display: inline-block;
}
.myFileUpload
{
    position: absolute;
    display: block;
    width: 100px;
    height: 40px;
    opacity: 0;
    cursor: pointer;
    // top: 10px;
}
.levelTitle{
  background-color: #5A9CF8;
  margin-left: 20px;
  margin-right: 20px;
  height: 30px;
  color: white;
  line-height: 30px;
  font-size: 15px;
  padding-left: 10px;
  // margin-bottom: 5px;
}
.levelTitle1{
  float: left;
  background-color: #D0D4E8;
  margin-left: 20px;
  margin-right: 70px;
  height: 30px;
  color: #333;
  line-height: 30px;
  font-size: 15px;
  padding-left: 10px;
  margin-top: 1px;
}
.levelTitle2{
  float: left;

  background-color: #E9EBF4;
  margin-left: 20px;
  margin-right: 70px;
  height: 30px;
  color: #333;
  line-height: 30px;
  font-size: 15px;
  padding-left: 10px;
  margin-top: 1px;
}

.btn {
    width: 160px;
}

.pay-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.QRcode {
    width: 220px;
    height: 200px;
    background: url("../../assets/image/qrcode.png");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    margin-top: 20px;

    .text {
        width: 100%;
        word-break: break-word;
        text-align: center;
    }
}

.el-table /deep/ .warning-row {
  background: #D0D4E8;
}

.el-table /deep/ .success-row {
  background: #E9EBF4;
}
.hide-table-header /deep/ .el-table__header-wrapper {
  display: none;
}
</style>